import _ from 'lodash';
import { storage } from '@/plugins/firebase';
import axios from '../../lib/axios';
import notify from '../../utilities/notifications';
//import io from "socket.io-client";
import localforage from '../../plugins/localforage';
import { AES } from 'crypto-js';
import { enc } from 'crypto-js';

//const socket = io(process.env.VUE_APP_ASL_BASE_URL);
const assessmentModule = {
  namespaced: true,
  state: {
    assessment: {},
    assessmentGrade: {},
    assessments: [],
    assessmentData: {},
    uploadProgress: 0,
  },
  mutations: {
    setUploadProgress(state, progress) {
      state.uploadProgress = progress;
    },
    setAssessment(state, assessment) {
      state.assessment = assessment;
    },
    setAssessmentGrade(state, assessmentGrade) {
      state.assessmentGrade = assessmentGrade;
    },
    setAssessments(state, assessments) {
      state.assessments = assessments;
    },
  },
  actions: {
    async updateStudentPoints({ dispatch }, payload) {
      return await axios
        .patch('/assessment/update-points', payload)
        .then((response) => {
          if (response.data.status == 'success') {
            notify({
              status: 'success',
              title: 'Success',
              message: 'Student points updated successfully!',
            });
            return true;
          } else {
            notify({
              status: 'info',
              title: 'Info',
              message: 'Failed to update student points, please try again later',
            });
            return false;
          }
        })
        .catch((error) => {
          notify({
            status: 'error',
            title: 'Error',
            message: error.detail,
          });
          return false;
        });
    },
    async takeAssessment({ commit }, payload) {
      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        try {
          var response = await axios.post(`/assessment/${payload.id}/take/${payload.attempt}`);
          if (response.data.status == 'success') {
            //   socket.emit("assessment:take", payload);
            return true;
          } else {
            notify({
              status: 'info',
              title: 'Info',
              message: 'Failed to proceed to assessment proper, please try again later',
            });
            return false;
          }
        } catch (error) {
          notify({
            status: 'error',
            title: 'Error',
            message: error.detail,
          });

          return false;
        }
      } else {
        // temporarily just set to true to start assessment directly
        return true;
      }
    },
    async deleteAssessment({ dispatch }, payload) {
      return await axios
        .delete(`/assessment/activateAssessment/${payload.assessmentId}`)
        .then((response) => {
          if (response.data.status == 'success') {
            dispatch('getAssessments', payload.subjectId);
            notify({
              title: 'Success',
              status: 'success',
              message: 'Assessment deleted successfully',
            });
          } else {
            notify({
              title: 'Error',
              status: 'error',
              message: 'Failed to delete assessment',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          notify({
            title: 'Error',
            status: 'error',
            message: error.detail,
          });
        });
    },
    async activateAssessment({ dispatch }, payload) {
      return await axios
        .patch(`/assessment/activateAssessment/${payload.assessmentId}`, {
          status: payload.status,
        })
        .then((response) => {
          if (response.data.status == 'success') {
            dispatch('getAssessments', payload.subjectId);
            notify({
              title: 'Success',
              status: 'success',
              message: `Assessment is now ${payload.status ? 'active' : 'inactive'}`,
            });
          } else {
            notify({
              title: 'Error',
              status: 'error',
              message: `Failed to ${payload.status ? 'activate' : 'deactivate'} assessment`,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          notify({
            title: 'Error',
            status: 'error',
            message: error.detail,
          });
        });
    },
    async recordAssessment({ dispatch }, payload) {
      return await axios
        .patch(`/assessment/recordAssessment/${payload.assessmentId}`, payload)
        .then((response) => {
          if (response.data.status == 'success') {
            dispatch('getAssessments', payload.subjectId);
            notify({
              title: 'Success',
              status: 'success',
              message: `Assessment is now ${payload.recorded ? 'recorded' : 'unrecorded'}`,
            });
          } else {
            notify({
              title: 'Error',
              status: 'error',
              message: `Failed to ${payload.status ? 'record' : 'unrecord'} assessment`,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          notify({
            title: 'Error',
            status: 'error',
            message: error.detail,
          });
        });
    },
    async assignAssessmentToStudents({ dispatch }, payload) {
      return await axios
        .post('/assessment/assignAssessment', payload)
        .then((response) => {
          if (response.data.status == 'success') {
            dispatch('getAssessments', payload.subjectId);
            notify({
              title: 'Success',
              status: 'success',
              message: 'Assessment assigned successfully',
            });
          } else {
            notify({
              title: 'Error',
              status: 'error',
              message: 'Failed to assign assessment',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          notify({
            title: 'Error',
            status: 'error',
            message: error.detail,
          });
        });
    },
    async getFileUrl({ commit }, fileName) {
      return await storage
        .ref()
        .child(`assessment-files/${fileName}`)
        .getDownloadURL()
        .then((url) => {
          return url;
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
    },
    async deleteFile({ commit }, fileName) {
      return await storage
        .ref()
        .child(`assessment-files/${fileName}`)
        .delete()
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    async uploadFileToFirebase({ commit }, payload) {
      return new Promise(function (resolve, reject) {
        const uploadAssessmentFile = storage.ref().child(`assessment-files/${payload.name}`).put(payload);

        uploadAssessmentFile.on(
          'state_changed',
          (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // commit("setUploadProgress", progress);
          },
          (error) => {
            console.log(error);
            reject();
          },
          () => {
            resolve();
          }
        );
      });
    },
    async syncAssessment({ dispatch }, payload) {
      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        try {
          var storedAnswerForm = await localforage.getItem(`stored_answer_form_${payload.assessmentId}`);
          if (storedAnswerForm) {
            var response = await axios.post('/assessment/sync', storedAnswerForm);

            if (response.data.status == 'success') {
              await localforage.removeItem(`stored_answer_form_${payload.assessmentId}`);
              notify({
                title: 'Success',
                status: 'success',
                message: 'Answers turned in successfully',
              });

              dispatch('getAssessments', payload.subjectId);
            } else {
              notify({
                title: 'Error',
                status: 'error',
                message: 'Failed to turn in answers',
              });
            }
          } else {
            notify({
              title: 'Info',
              status: 'info',
              message: 'Please refresh your browser',
            });
          }
        } catch (error) {
          notify({
            title: 'Error',
            status: 'error',
            message: error.detail,
          });
        }
      } else {
        notify({
          title: 'Info',
          status: 'info',
          message: 'Please make sure you have internet connection and try again',
        });
      }
    },
    async submitAnswerForm({ commit }, answerForm) {
      try {
        if (answerForm.studentAssessmentId === 0) {
          await localforage.setItem(`stored_answer_form_${answerForm.assessmentId}`, answerForm);
          return true;
        } else {
          var response = await axios.patch(`/assessment/submit/${answerForm.studentAssessmentId}`, answerForm);

          //always clear stored answer to avoid conflicts if there is an existing resumable attempt.
          await localforage.removeItem(`stored_answer_form_${answerForm.assessmentId}`);

          if (response.data.status == 'success') {
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async submitAssessment({ commit }, assessmentForm) {
      let endpoint = assessmentForm.isUpdating ? `/assessment/update/${assessmentForm.id}` : '/assessment/create',
        method = assessmentForm.isUpdating ? 'patch' : 'post';
      return axios[method](endpoint, assessmentForm)
        .then((response) => {
          if (response.data.status == 'success') {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    async getAssessmentGrade({ commit }, payload) {
      return await axios
        .get(`/assessment/${payload.assessmentId}/grade`, {
          params: { studentId: payload.studentId },
        })
        .then((response) => {
          if (response.data.status == 'success') {
            commit('setAssessmentGrade', response.data.assessment);
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    async fetchFileData({ commit }, url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('GET', url, true);
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error('Failed to fetch file data.'));
          }
        };
        xhr.onerror = function () {
          reject(new Error('Failed to fetch file data.'));
        };
        xhr.send();
      });
    },
    async downloadOfflineAssessment({ dispatch }, assessmentData) {
      try {
        const assessmentId = assessmentData.id;
        const subjectId = assessmentData.subjectId;
        var questionBlobs = [];
        var choiceBlobs = [];
        var response = await axios.get(`/assessment/${assessmentId}`);
        if (response.data.status == 'success') {
          let assessment = response.data.assessment;
          if (assessment.offline) {
            const secretKey = assessment.accessCode;
            await Promise.all(
              (assessment.assessmentTests ?? [])?.map(async (test) => {
                await Promise.all(
                  (test.questions ?? [])?.map(async (question) => {
                    if (!question.image) {
                      console.log('no question image found');
                    } else {
                      let questionPreview = await dispatch('getFileUrl', question.image);
                      const response = await fetch(questionPreview);
                      const fileBlob = await response.blob();

                      // Store the file in localForage or any other storage solution
                      await localforage.setItem(`question_${question.id}`, fileBlob);
                      questionBlobs.push(question.id);
                    }

                    await Promise.all(
                      (question.choices ?? [])?.map(async (choice) => {
                        if (!choice.choice) {
                          console.log('no choice image found');
                        } else {
                          let choicePreview = await dispatch('getFileUrl', choice.choice);
                          const response = await fetch(choicePreview);
                          const fileBlob = await response.blob();

                          // Store the file in localForage or any other storage solution
                          await localforage.setItem(`choice_${choice.id}`, fileBlob);
                          choiceBlobs.push(choice.id);
                        }
                      })
                    );
                  })
                );
              })
            );

            if (questionBlobs?.length > 0) {
              await localforage.setItem(`question_blobs_${assessmentId}`, questionBlobs);
            }
            if (choiceBlobs?.length > 0) {
              await localforage.setItem(`choice_blobs_${assessmentId}`, choiceBlobs);
            }

            var jsonString = JSON.stringify(assessment);
            var encryptedData = AES.encrypt(jsonString, secretKey).toString();
            await localforage.setItem(`offline_assessment_${assessmentId}`, encryptedData);

            const offlineAssessments = await localforage.getItem(`offline_assessments_${subjectId}`);
            if (offlineAssessments?.length === 0 || !offlineAssessments) {
              var newOfflineAssessments = [];
              newOfflineAssessments.push(assessmentData);
              await localforage.setItem(`offline_assessments_${subjectId}`, newOfflineAssessments);
            } else {
              var exist = offlineAssessments.find((offlineAssessment) => offlineAssessment.id === assessmentId);
              if (!exist) {
                offlineAssessments.push(assessmentData);
              }
              await localforage.setItem(`offline_assessments_${subjectId}`, offlineAssessments);
            }
            dispatch('getAssessments', subjectId);
          } else {
            notify({
              title: 'Error',
              status: 'error',
              message: 'Assessment is not available to download, please refresh your browser',
            });
          }
        } else {
          notify({
            title: 'Error',
            status: 'error',
            message: 'Failed to fetch assessment data, please refresh your browser',
          });
        }
      } catch (error) {
        console.log(error);
        notify({
          title: 'Error',
          status: 'error',
          message: 'Failed to download assessment',
        });
      }
    },
    async getAssessment({ commit }, assessmentId) {
      if (!assessmentId) {
        return false;
      }

      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        try {
          var response = await axios.get(`/assessment/${assessmentId}`);
          if (response.data.status == 'success') {
            commit('setAssessment', response.data.assessment);
            return true;
          } else {
            notify({
              title: 'Error',
              status: 'error',
              message: 'Failed to fetch assessment',
            });
            return false;
          }
        } catch (error) {
          notify({
            title: 'Error',
            status: 'error',
            message: 'An error occured, please try again',
          });
          return false;
        }
      } else {
        try {
          var assessment = await localforage.getItem(`offline_assessment_${assessmentId}`);
          // check if assessment is still encrypted, the only possible outcome of assessment is either it is a string or a json object
          if (typeof assessment === 'string') {
            // decrypt assessment
            // get stored access code fail otherwise
            var secretKey = await localforage.getItem(`access_code_${assessmentId}`);
            var decryptedBytes = AES.decrypt(assessment, secretKey);
            var decryptedString = decryptedBytes.toString(enc.Utf8);
            assessment = JSON.parse(decryptedString);
          }

          if (!assessment) {
            notify({
              title: 'Error',
              status: 'error',
              message: 'Failed to fetch assessment',
            });

            return false;
          } else {
            commit('setAssessment', assessment);
            return true;
          }
        } catch (error) {
          notify({
            title: 'Error',
            status: 'error',
            message: 'Failed to fetch assessment',
          });
          console.log(error);

          return false;
        }
      }
    },
    async getAssessments({ commit }, subjectId) {
      if (!subjectId) {
        return false;
      }

      var downloadedAssessments = await localforage.getItem(`offline_assessments_${subjectId}`);
      var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        try {
          var response = await axios.get(`/assessments/${subjectId}`);

          if (response.data.status == 'success') {
            var fetchedAssessments = response.data.assessments;
            await Promise.all(
              (fetchedAssessments ?? [])?.map(async (fetchedAssessment) => {
                var hasOfflineProgress = await localforage.getItem(`stored_answer_form_${fetchedAssessment.id}`);
                fetchedAssessment.accessible = false;
                fetchedAssessment.inputCode = '';
                fetchedAssessment.pendingSync = hasOfflineProgress ?? false;
                fetchedAssessment.downloaded = !!downloadedAssessments?.find((assessment) => assessment.id === fetchedAssessment.id);
              })
            );
            commit('setAssessments', fetchedAssessments);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          if (downloadedAssessments?.length === 0 || downloadedAssessments === null || downloadedAssessments === '' || downloadedAssessments === undefined) {
            commit('setAssessments', []);
          } else {
            await Promise.all(
              (downloadedAssessments ?? [])?.map(async (assessment) => {
                var hasOfflineProgress = await localforage.getItem(`stored_answer_form_${assessment.id}`);
                assessment.downloaded = true;
                assessment.inputCode = '';
                assessment.pendingSync = hasOfflineProgress ?? false;
                assessment.accessible = false;
              })
            );
            commit('setAssessments', downloadedAssessments);
          }
        } catch (e) {
          notify({
            status: 'error',
            title: 'Fetch Assessments Failed',
            message: 'No data available',
          });
        }
      }
    },
  },
};

export default assessmentModule;
